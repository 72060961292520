import Column from "../../../../../components/containers/Column";
import { Seprator } from "../../../../../components/Exotics/Seprator";
import SubmitEditsForm from "../../../../../components/forms/SubmitEditsForm";
import GenericText from "../../../../../components/texts/GenericText";
import { IOrderDetails } from "../../../../../services/api/cinema/interface/IOrder";

const VoucherOrder = ({
  voucher,
}: {
  voucher: IOrderDetails["productsDetails"][0] & {
    vouchers: IOrderDetails["vouchersOrderPaid"];
    totalPrice: string;
  };
  title: string;
}) => {
  return (
    <SubmitEditsForm
      title={voucher.productInfo.name}
      buttonProps={{ style: { display: "none" } }}
      renderInsideComponents={() => (
        <Column margin={"10px 0px 0px"} alignItems="flex-start">
          {voucher.vouchers.length > 0 && (
            <>
              <Seprator />
              <Column>
                <GenericText
                  size={"h6"}
                  margin={"9px 0px 10px"}
                  alignSelf="center"
                >
                  Vouchers
                </GenericText>
                <Column alignItems="flex-start">
                  <ol>
                    {voucher.vouchers.map((voucher, i) => (
                      <li
                        key={`list-voucher-order-details-${voucher.productId}-${i}`}
                      >
                        <GenericText>{voucher.voucherCode}</GenericText>
                      </li>
                    ))}
                  </ol>
                </Column>
              </Column>
            </>
          )}

          <Seprator />
          <Column margin={"0px 20px 0px"} alignItems="flex-start">
            <GenericText
              margin={"10px 0px 0px"}
            >{`Quantidade: ${voucher.quantity}`}</GenericText>
            <GenericText
              margin={"5px 0px 0px"}
            >{`Total: R$${voucher.totalPrice.replace(".", ",")}`}</GenericText>
          </Column>
        </Column>
      )}
    />
  );
};

export default VoucherOrder;

import Column from "../../../../../components/containers/Column";
import SubmitEditsForm from "../../../../../components/forms/SubmitEditsForm";
import Input from "../../../../../components/Input";
import GenericText from "../../../../../components/texts/GenericText";
import { IOrderDetails } from "../../../../../services/api/cinema/interface/IOrder";

const EstablishmentInformation = ({ basic }: { basic: IOrderDetails }) => {
  const { establishmentInfo } = basic;
  return (
    <SubmitEditsForm
      title="Informações do Estabelecimento"
      buttonProps={{ style: { display: "none" } }}
      renderInsideComponents={() => (
        <Column margin={"10px 0px 0px"} alignItems="flex-start">
          <Column>
            <GenericText size="h6" margin={"10px"}>
              Logo
            </GenericText>
            <img src={establishmentInfo.images.logo} width={90} alt="logo" />
          </Column>
          <Input
            name="name"
            disabled
            value={establishmentInfo.name}
            width={"100%"}
            label="Nome"
            type="text"
            margin={"5px 0px"}
          />
          <Input
            name="rules"
            disabled
            value={establishmentInfo.rules}
            width={"100%"}
            rows={9}
            label="Regras"
            type="textArea"
            margin={"5px 0px"}
          />
          <Input
            name="instagram"
            disabled
            value={establishmentInfo.instagram}
            width={"100%"}
            rows={9}
            label="Instagram"
            type="text"
            margin={"5px 0px"}
          />
          <Input
            name="primaryColor"
            width={"100%"}
            value={establishmentInfo.primaryColor}
            label="Cor principal"
            placeholder="#ffffff"
            type="color"
            disabled
            margin={"5px 0px"}
          />
          <Input
            name="status"
            width={"100%"}
            value={establishmentInfo.active ? "Ativo" : "Inativo"}
            label="Status"
            type="text"
            disabled
            margin={"5px 0px"}
          />
          <Input
            name="Vouchers disponiveis"
            width={"100%"}
            value={establishmentInfo.availableTickets ? "Sim" : "Não"}
            label="Vouchers disponiveis"
            type="text"
            disabled
            margin={"5px 0px"}
          />
        </Column>
      )}
    />
  );
};

export default EstablishmentInformation;

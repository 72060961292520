/* eslint-disable no-throw-literal */
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { axiosProvider } from "../../utils/axiosProvider";
import queryFormatter from "../../utils/queryFormatter";
import { IPatchIntegration } from "./interface";

const createOrUpdateSync = async ({
  id,
  products,
  type,
}: {
  id: number;
  products: Number[];
  type: "update" | "create";
}): Promise<void> => {
  const correctBody = {
    companyId: id,
    synchronism: {
      type: "Tombamento",
      details: [
        {
          name: "Tombamento",
          categories: products,
        },
      ],
    },
  };
  const URL = queryFormatter({
    route: `internet/dashboard/synchronism/data/`,
  });
  try {
    const { data } = await axiosProvider({
      method: type === "update" ? "PATCH" : "POST",
      url: URL,
      data: correctBody,
    });
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default createOrUpdateSync;

/* eslint-disable import/no-anonymous-default-export */
import CreateProduct from "./CreateProduct";
import CreateVoucher from "./CreateVoucher";
import GetBrand from "./GetBrand";
import GetBrandDetails from "./GetBrandDetails";
import GetOrderId from "./GetOrderId";
import GetOrders from "./GetOrders";
import GetProductsCinema from "./GetProductsCinema";
import GetProductType from "./GetProductType";
import GetSummary from "./GetSummary";
import GetSummaryProducts from "./GetSummaryProducts";
import GetSummaryVoucher from "./GetSummaryVoucher";
import GetVoucherExample from "./GetVoucherExample";
import GetVouchers from "./GetVouchers";
import PutVoucher from "./PutVoucher";
import UpdateCinema from "./UpdateCinema";
import UpdateImages from "./UpdateImages";
import UpdateProduct from "./UpdateProduct";

class service {
  getSummary = GetSummary;
  getBrand = GetBrand;
  getBrandDetails = GetBrandDetails;
  updateCinema = UpdateCinema;
  updateImages = UpdateImages;
  getProductType = GetProductType;
  getSummaryProducts = GetSummaryProducts;
  createVoucher = CreateVoucher;
  getProductsCinema = GetProductsCinema;
  createProduct = CreateProduct;
  updateProduct = UpdateProduct;
  getVouchers = GetVouchers;
  getSummaryVoucher = GetSummaryVoucher;
  putVoucher = PutVoucher;
  getVoucherExample = GetVoucherExample;
  getOrders = GetOrders;
  getOrderId = GetOrderId;
}

export default new service();

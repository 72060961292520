/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import IOrder from "./interface/IOrder";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

const GetOrders = async ({
  limit,
  page,
  companyId,
  establishmentId,
  cpf,
  status,
}: {
  page?: number;
  limit?: number;
  companyId?: number;
  establishmentId?: number;
  cpf?: string;
  status?: string;
}): Promise<IOrder[]> => {
  const URL = queryFormatter({
    route: "cinema/dashboard/orders/all",
    querys: {
      page: (page ?? 1) - 1,
      limit,
      companyId,
      establishmentId,
      cpf,
      status,
    },
  });
  try {
    const { data }: AxiosResponse<IOrder[]> = await axiosProvider.get(URL);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default GetOrders;

/* eslint-disable no-throw-literal */
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { axiosProvider } from "../../utils/axiosProvider";
import queryFormatter from "../../utils/queryFormatter";
import { IPatchIntegration } from "./interface";

const patchIntegration = async (
  id: number,
  body: IPatchIntegration
): Promise<any> => {
  const URL = queryFormatter({
    route: `internet/dashboard/integration/${id}`,
  });
  try {
    const { data } = await axiosProvider.patch(URL, body);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default patchIntegration;

import moment from "moment";
import { IRow } from "../../../components/Table/interface/IRow";
import RowId from "../../../components/Table/RowId";
import RowStatus from "../../../components/Table/RowStatus";
import RowText from "../../../components/Table/RowText";
import { IBrandData } from "../../../services/api/cinema/interface/IBrand";

export const ordersHome = (
  defaultProps: IRow = {},
  establishment: IBrandData[]
) => {
  const { onClick = () => {} } = defaultProps;
  return [
    {
      name: "ID",
      cell: (row: any) => (
        <RowId {...defaultProps} onClick={() => onClick(row)}>
          {row.id}
        </RowId>
      ),
    },

    {
      name: "Cinema",
      cell: (row: any) => (
        <img
          {...defaultProps}
          src={row.establishmentInfo.images.logo}
          width={50}
          alt="logo"
          style={{ padding: "2px 0px" }}
          onClick={() => onClick(row)}
        />
      ),
    },

    {
      name: "Valor ",
      cell: (row: any) => (
        <RowText {...defaultProps} onClick={() => onClick(row)}>
          {`R$ ${row.totalPrice.replace(".", ",")}`}
        </RowText>
      ),
    },
    {
      name: "Comprado em ",
      cell: (row: any) => (
        <RowText {...defaultProps} onClick={() => onClick(row)}>
          {moment(row.createdAt).format("DD/MM/YYYY")}
        </RowText>
      ),
    },

    {
      name: "Status",
      cell: (row: any) => (
        <RowStatus
          {...defaultProps}
          label={
            row.status === "paid"
              ? "Pago"
              : row.status === "expired"
              ? "Expirado"
              : "Aguardando"
          }
          status={row.status === "paid" ? 1 : row.status === "expired" ? 0 : 2}
          onClick={() => onClick(row)}
        />
      ),
    },
  ];
};

/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { IGetIntegration } from "./interface";

const getIntegration = async (id: number): Promise<IGetIntegration> => {
  const URL = queryFormatter({
    route: `internet/dashboard/integration/${id}`,
  });

  try {
    const { data }: AxiosResponse<IGetIntegration> = await axiosProvider.get(
      URL
    );
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default getIntegration;

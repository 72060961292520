/* eslint-disable react-hooks/rules-of-hooks */
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import CinemaService from "../../../../services/api/cinema/CinemaService";
import EntityHeader from "../../../../components/headers/EntityHeader";
import { CSCContainer } from "../../../../components/containers/ContentSpacingContainer/styles";
import VoucherOrder from "../DetailsBoxes/VoucherOrder";
import LoaderGlobal from "../../../../components/Exotics/loaders/LoaderGlobal";
import { useMemo } from "react";
import UserInformation from "../DetailsBoxes/UserInformation";
import EstablishmentInformation from "../DetailsBoxes/EstablishmentInformation";

const OrderDetails = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  if (!id) {
    navigate("/");
    return <></>;
  }

  const { data: order, isLoading } = useQuery({
    queryKey: ["get-order-details-cinema", id],
    queryFn: () => CinemaService.getOrderId(Number(id)),
  });

  const vouchers = useMemo(() => {
    if (order) {
      return order.productsDetails.reduce((acc, product) => {
        const matchingVouchers = order.vouchersOrderPaid.filter(
          (voucher) => voucher.productId === product.productId
        );

        const existingProduct = acc.find(
          (item) => item.productInfo.productId === product.productId
        );

        if (existingProduct) {
          existingProduct.quantity += product.quantity;
          existingProduct.vouchers.push(...matchingVouchers);
        } else {
          acc.push({
            ...product,
            vouchers: matchingVouchers,
            totalPrice: order.totalPrice,
          });
        }

        return acc;
      }, [] as Array<(typeof order.productsDetails)[0] & { vouchers: typeof order.vouchersOrderPaid; totalPrice: string }>);
    }
  }, [order]);

  return (
    <>
      <EntityHeader
        title={"Detalhes do pedido "}
        name={order?.establishmentInfo.name || ""}
        brand={order?.establishmentInfo?.images?.logo}
        names={[]}
        id={order?.id.toString() || ""}
        status={order?.status !== "expired" ? "active" : "inactive"}
        statusButtonProps={{
          text: order?.status !== "expired" ? "Pago" : "Expirado",
        }}
      />
      <CSCContainer>
        {vouchers &&
          vouchers.map((voucher, i) => (
            <VoucherOrder
              key={`list-voucher-${voucher.productId}-${i}`}
              title={voucher.productInfo.name}
              voucher={voucher}
            />
          ))}
        {order && <UserInformation basic={order} />}
        {order && <EstablishmentInformation basic={order} />}
      </CSCContainer>
      {isLoading && <LoaderGlobal />}
    </>
  );
};

export default OrderDetails;

import { useNavigate } from "react-router-dom";
import SubmitEditsForm from "../../../../../components/forms/SubmitEditsForm";
import Input from "../../../../../components/Input";
import { IOrderDetails } from "../../../../../services/api/cinema/interface/IOrder";

const UserInformation = ({ basic }: { basic: IOrderDetails }) => {
  const navigate = useNavigate();
  return (
    <SubmitEditsForm
      title="Usuario"
      buttonProps={{
        text: "+ detalhes",
        onClick: () =>
          navigate(
            `/associados/detalhes/${basic.user.companyId}-${basic.user.cpf}`
          ),
      }}
      renderInsideComponents={() => (
        <>
          <Input
            name="companyId"
            disabled
            value={basic.user?.companyId || ""}
            width={"100%"}
            label="Id da empresa"
            type="text"
            margin={"5px 0px"}
          />
          <Input
            name="name"
            disabled
            value={basic.user?.name || ""}
            width={"100%"}
            label="Nome"
            type="text"
            margin={"5px 0px"}
          />
          <Input
            name="cpf"
            disabled
            value={basic.user?.cpf || ""}
            width={"100%"}
            label="Cpf"
            maskType="cpf"
            type="text"
            margin={"5px 0px"}
          />
          <Input
            name="email"
            disabled
            value={basic.user?.email || ""}
            width={"100%"}
            label="Email"
            type="text"
            margin={"5px 0px"}
          />
        </>
      )}
    />
  );
};

export default UserInformation;

/* eslint-disable no-throw-literal */
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { axiosProvider } from "../../utils/axiosProvider";
import queryFormatter from "../../utils/queryFormatter";
import { IGetIntegration } from "./interface";

const postIntegration = async (body: IGetIntegration): Promise<any> => {
  const URL = queryFormatter({
    route: `internet/dashboard/integration`,
  });

  try {
    const { data } = await axiosProvider.post(URL, body);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default postIntegration;

import { useEffect, useRef } from "react";
import BoxAction from "../../../../../components/BoxAction";
import Button from "../../../../../components/buttons/Button";
import Column from "../../../../../components/containers/Column";
import { ICustomer } from "../../../../../interfaces/customer/ICustomer";
import { useMutation } from "@tanstack/react-query";
import CustomerService from "../../../../../services/api/customer/CustomerService";
import LoaderGlobal from "../../../../../components/Exotics/loaders/LoaderGlobal";
import { toast } from "react-toastify";

const ExcelContainer = ({ basic }: { basic: ICustomer }) => {
  const fileInputRefActive = useRef<HTMLInputElement | null>(null);
  const fileInputRefInative = useRef<HTMLInputElement | null>(null);

  const { mutate, isPending, isSuccess, isError, error } = useMutation({
    mutationKey: ["post-excel-file"],
    mutationFn: CustomerService.postExcel,
  });

  const handleFileChangeAndSubmitFile = (event: any) => {
    const selectedFile = event.target.files[0];
    const type = event.target.name;
    const formData = new FormData();
    formData.append("excelFile", selectedFile);
    formData.append("companyId", basic.id.toString());
    mutate({ type, formData });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Dados salvos com sucesso");
    } else if (isError && error) {
      const dataError = error as any;
      if (dataError?.link) {
        toast.error("Alguns associados apresentam erro");
        const aElement = document.createElement("a");
        aElement.href = dataError?.link;
        aElement.download = "associados-com-falha";
        aElement.style.display = "none";
        document.body.appendChild(aElement);
        aElement.click();
        document.body.removeChild(aElement);
      }
      return;
    }
  }, [error, isError, isSuccess]);

  return (
    <>
      <Column
        justifyContent="space-between"
        style={{ gap: 20 }}
        margin={"20px 0px"}
      >
        <BoxAction
          title="Lista de Ativação/Cadastro"
          description="Arquivo excel para registro de associados.
          Certifique-se de que o arquivo contenha todos os requisitos básicos necessários para essa ação."
          renderActionButton={() => (
            <div style={{ alignSelf: "center" }}>
              <input
                hidden
                name="active"
                ref={fileInputRefActive}
                onChange={(e) => handleFileChangeAndSubmitFile(e)}
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              <Button
                onClick={() => fileInputRefActive?.current?.click()}
                text="Registrar"
                width="180px"
                height={"40px"}
              />
            </div>
          )}
          renderExampleFile={{
            textFile: "Arquivo de exemplo",
            type: "active",
          }}
        />
        <BoxAction
          title="Lista de inativação"
          description="Arquivo excel para inativação de associados
          Certifique-se de que o arquivo contenha todos os requisitos básicos necessários para essa ação."
          renderActionButton={() => (
            <div style={{ alignSelf: "center" }}>
              <input
                hidden
                name="inative"
                ref={fileInputRefInative}
                type="file"
                onChange={handleFileChangeAndSubmitFile}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              <Button
                onClick={() => fileInputRefInative?.current?.click()}
                text="Inativar"
                width="180px"
                height={"40px"}
              />
            </div>
          )}
          renderExampleFile={{
            textFile: "Arquivo de exemplo",
            type: "inative",
          }}
        />
      </Column>
      {isPending && <LoaderGlobal />}
    </>
  );
};

export default ExcelContainer;

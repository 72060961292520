/* eslint-disable import/no-anonymous-default-export */
import createOrUpdateSync from "./createOrUpdateSync";
import getIntegration from "./getIntegration";
import getSync from "./getSync";
import patchIntegration from "./patchIntegration";
import postIntegration from "./postIntegration";

class Service {
  getIntegration = getIntegration;
  postIntegration = postIntegration;
  patchIntegration = patchIntegration;
  getSync = getSync;
  createOrUpdateSync = createOrUpdateSync;
}
export default new Service();

/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@tanstack/react-query";
import Box from "../../../components/containers/Box";
import GenericText from "../../../components/texts/GenericText";
import CinemaService from "../../../services/api/cinema/CinemaService";
import Table from "../../../components/Table";
import { ordersHome } from "../Tables/ordersHome";
import Column from "../../../components/containers/Column";
import Row from "../../../components/containers/Row";
import Select from "../../../components/Select";
import { useEffect, useState } from "react";
import Button from "../../../components/buttons/Button";
import CustomerService from "../../../services/api/customer/CustomerService";
import Input from "../../../components/Input";
import changeStateByInputName from "../../../helpers/state/changeStateByInputName";
import { useNavigate } from "react-router-dom";

const Orders = () => {
  const navigate = useNavigate();

  const [cacheOrders, setCacheOrders] = useState<Record<string, any>>({});
  const [removeDoubleRender, setRemoveDoubleRender] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState<Record<string, any>>({
    companyId: undefined,
    establishmentId: undefined,
    cpf: undefined,
    status: "paid",
  });
  const [focusedFilters, setFocusedFilters] = useState<Record<string, any>>({});
  const [companyName, setCompanyName] = useState("");

  const { data: establishments } = useQuery({
    queryKey: ["get-brand-cinema"],
    queryFn: () =>
      CinemaService.getBrand({
        page: 0,
        limit: 50,
        active: true,
      }),
  });

  const { isPending, mutate } = useMutation({
    mutationKey: ["get-orders-cinema"],
    mutationFn: CinemaService.getOrders,
    onSuccess: (data, variables) => {
      const key = JSON.stringify({ page, limit, ...variables });
      setFocusedFilters(variables as never);

      setCacheOrders((prevData) => ({
        ...prevData,
        [key]: data,
      }));
    },
  });

  const { data: customers } = useQuery({
    queryKey: ["company-query", companyName],
    queryFn: () =>
      CustomerService.getCompany({
        limit: 20,
        page: 1,
        name: companyName,
        type: "active",
      }),
  });

  function init() {
    const key = JSON.stringify({ page, limit, ...filters });
    if (!isPending && !cacheOrders[key]?.length) {
      mutate({ page, limit });
    }
  }

  const handleSearch = (
    props: {
      cache?: boolean;
      reset?: boolean;
      page?: number;
      limit?: number;
    } = {}
  ) => {
    setPage(props.reset || !props.page ? 1 : props.page);
    if (
      props.cache &&
      cacheOrders[
        JSON.stringify({
          page: props.page || 1,
          limit: props.limit || limit,
          ...filters,
        })
      ] &&
      !props.reset
    ) {
      return;
    }
    setFocusedFilters(filters);
    mutate({ page: props.page || 1, limit: props.limit || limit, ...filters });
  };

  useEffect(() => {
    if (removeDoubleRender) {
      setRemoveDoubleRender(false);
      return;
    }
    init();
  }, [removeDoubleRender]);

  return (
    <>
      <Box width={"90%"} margin={"10px 0px"} shadow>
        <Column width={"90%"} alignItems="flex-start">
          <GenericText
            alignSelf="flex-start"
            size="h3"
            margin={"0px 0px 20px 0px"}
          >
            Buscar pedidos
          </GenericText>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSearch({ reset: true });
            }}
          >
            <Row
              width="100%"
              justifyContent="space-between"
              wrap="wrap"
              margin={"5px 5px"}
            >
              <Select
                data={
                  establishments?.data
                    ? [
                        {
                          name: "Estabelecimento",
                          id: undefined,
                        },
                        ...establishments?.data,
                      ]
                    : []
                }
                getOptionLabel={(item: any) => item?.name}
                getOptionValue={(item: any) => item?.id}
                width="240px"
                placeholder="Estabelecimento"
                onChange={(e: any) => {
                  setFilters((state) => ({
                    ...state,
                    establishmentId: e?.id,
                  }));
                }}
              />
              <Select
                data={
                  customers?.data
                    ? [
                        {
                          name: "Empresa",
                          id: undefined,
                        },
                        ...customers?.data,
                      ]
                    : []
                }
                getOptionLabel={(item: any) => item?.name}
                getOptionValue={(item: any) => item?.id}
                onInputChange={(e) => {
                  setCompanyName(e);
                }}
                width="240px"
                placeholder="Empresa"
                onChange={(e: any) => {
                  setFilters((state) => ({
                    ...state,
                    companyId: e?.id,
                  }));
                }}
              />
              <Select
                data={[
                  {
                    name: "Status",
                    id: undefined,
                  },
                  {
                    name: "Pago",
                    id: "paid",
                  },
                  {
                    name: "Expirado",
                    id: "expired",
                  },
                  {
                    name: "Todos",
                    id: "",
                  },
                ]}
                getOptionLabel={(item: any) => item?.name}
                getOptionValue={(item: any) => item?.id}
                value={
                  filters.status === "paid"
                    ? {
                        name: "Pago",
                        id: "paid",
                      }
                    : filters.status === "expired"
                    ? {
                        name: "Expirado",
                        id: "expired",
                      }
                    : {
                        name: "Todos",
                        id: "",
                      }
                }
                width="240px"
                placeholder="Status"
                onChange={(e: any) => {
                  setFilters((state) => ({
                    ...state,
                    status: e?.id,
                  }));
                }}
              />

              <Input
                name="cpf"
                onChange={(e) => changeStateByInputName(e, setFilters)}
                width="240px"
                value={filters.cpf}
                placeholder="cpf"
                maskType="cpf"
                margin={"5px 5px"}
              />
            </Row>
            <Button
              height={"35px"}
              width={"150px"}
              text="Buscar"
              margin={"5px 5px"}
              disabled={
                (filters?.companyId && !filters?.cpf) ||
                (!filters?.companyId && filters?.cpf)
              }
            />
          </form>
        </Column>
      </Box>
      <Box margin={"20px 0px"} width={"90%"}>
        <Table
          progressPending={isPending}
          onRowClicked={(row: any) => {
            navigate(`/cinema/detalhes/pedido/${row?.id}`);
          }}
          data={
            cacheOrders[JSON.stringify({ page, limit, ...focusedFilters })] ||
            []
          }
          columns={ordersHome(
            {
              onClick: (row: any) =>
                navigate(`/cinema/detalhes/pedido/${row?.id}`),
            },
            establishments?.data || []
          )}
          paginationTotalRows={999}
          selectableRows
          onChangeRowsPerPage={(newLimit, newPage) => {
            setLimit(newLimit);
            setPage(newPage);
            handleSearch({ cache: true, page: newPage, limit: newLimit });
          }}
          onChangePage={(newPage) => {
            if (newPage === page) {
              return;
            }
            handleSearch({ cache: true, page: newPage });
          }}
        />
      </Box>
    </>
  );
};
export default Orders;

export function getMaskType(maskType?: string) {
  if (!maskType) return "";
  if (maskType === "cnpj") return "99.999.999/9999-99";
  if (maskType === "color") return "#******";
  if (maskType === "tel") return "(99) 99999-9999";
  if (maskType === "cep") return "99999-999";
  if (maskType === "ip") return "999.99.999.99";

  return "999.999.999-99";
}
